/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import { EXPORT_INVOICE_URL } from '../../core/_requests'

type Props = {
  id: ID
}

const SubscriptionsPaymentsActionsCell: FC<Props> = ({id}) => {

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // return (
  //   <>
  //     <a
  //       href='#'
  //       className='btn btn-light btn-active-light-primary btn-sm'
  //       data-kt-menu-trigger='click'
  //       data-kt-menu-placement='bottom-end'
  //     >
  //       Actions
  //       <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
  //     </a>
  //     {/* begin::Menu */}
  //     <div
  //       className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
  //       data-kt-menu='true'
  //     >
  //       {/* begin::Menu item */}
  //       <div className='menu-item px-3'>
  //         <a className='menu-link px-3' onClick={openEditModal}>
  //           Download Invoice
  //         </a>
  //       </div>
  //       {/* end::Menu item */}

  //     </div>
  //     {/* end::Menu */}
  //   </>
  // )
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* 
        Filter is not needed here
      */}
      {/* <AllCustomersListFilter /> */}

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={() => window.open(EXPORT_INVOICE_URL + '/' + id)}>
        <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-2' />
        Invoice
      </button>
      {/* end::Export */}
    </div>
  )
}

export {SubscriptionsPaymentsActionsCell}
