import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

export const DASHBOARD_PLAN_REPORT = `${API_URL}/dashboard/plan/report`

export const DASHBOARD_SALES_REPORT = `${API_URL}/dashboard/sales/report`

export type PlanReportResponse = { month: string, count: number }

export type SalesReportResponse = { month: string, total: number }

export const getPlanReport = (): Promise<{userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number} | undefined> => {
    return axios
      .get(`${DASHBOARD_PLAN_REPORT}`)
      .then((response: AxiosResponse<Response<{userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number}>>) => response.data)
      .then((response: Response<{userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number}>) => response.result)
}

export const getSalesReport = (): Promise<{salesListReportInfo: SalesReportResponse[], totalSales: number} | undefined> => {
  return axios
    .get(`${DASHBOARD_SALES_REPORT}`)
    .then((response: AxiosResponse<Response<{salesListReportInfo: SalesReportResponse[], totalSales: number}>>) => response.data)
    .then((response: Response<{salesListReportInfo: SalesReportResponse[], totalSales: number}>) => response.result)
}
