/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import { FC } from 'react'
import { useListView } from '../../core/ListViewProvider'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { UserSurvey } from '../../core/_models'

type Props = {
  survey: UserSurvey
}

const UserSurveyInfoCell: FC<Props> = ({ survey }) => {
  const { setItemIdForUpdate } = useListView()
  
  const openEditModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setItemIdForUpdate(survey.id)
  }
  
  return(

  <div className='d-flex align-items-center'>

    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={openEditModal}>
        {survey.title}
      </a>
      <span>{survey.submissionCount} submissions</span>
    </div>
  </div>
)}

export { UserSurveyInfoCell }
