import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Payments,PaymentsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const User_URL = `${API_URL}/user`
// const GET_COUNTRY_URL = `${API_URL}/country`
// const GENDER_URL = `${API_URL}/gender`

const PACKAGE_PAYMENT_URL = `${API_URL}/package_payments`
const GET_PACKAGE_PAYMENT_URL = `${API_URL}/package_payments/query`
const EXPORT_INVOCE_URL = `${API_URL}/userPlans/downloadPDF`

// const getCountry = (): Promise<Country[] | undefined> => {
//   return axios
//     .get(`${GET_COUNTRY_URL}`)
//     .then((response: AxiosResponse<Response<Country[]>>) => response.data)
//     .then((response: Response<Country[]>) => response.result)
// }

// const getGender = (): Promise<Gender[] | undefined> => {
//   return axios
//     .get(`${GENDER_URL}`)
//     .then((response: AxiosResponse<Response<Gender[]>>) => response.data)
//     .then((response: Response<Gender[]>) => response.result)
// }


const getPaymentId = (id: string): Promise<Payments | undefined> => {
  return axios
    .get(`${PACKAGE_PAYMENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Payments>>) => response.data)
    .then((response: Response<Payments>) => response.result)
}

export { getPaymentId, EXPORT_INVOCE_URL }
