/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {
  expire_date: string
}

const SubscriptionsPaymentsExpireDateCell: FC<Props> = ({expire_date}) => {
  console.log('expire_date', expire_date)
  const expire_date_format = new Date(expire_date)
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    {/* <div className='d-flex flex-column'>
    {expire_date_format.toLocaleDateString('en-US', { day: '2-digit' })} {expire_date_format.toLocaleDateString('en-US', { month: 'long' })} {expire_date_format.getFullYear()}, {expire_date_format.toLocaleTimeString('en-US', {timeZone: 'IST', timeZoneName: 'short', hour: '2-digit', minute: '2-digit'})}
    </div> */}

      <div className='d-flex flex-column'>
        {expire_date_format.toLocaleTimeString('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
      </div>

  </div>
)}

export {SubscriptionsPaymentsExpireDateCell}
