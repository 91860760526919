import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { SubscriptionsPaymentsListHeader } from './components/header/SubscriptionsPaymentsListHeader'
import { SubscriptionsPaymentsTable } from './table/SubscriptionsPaymentsTable'
import { KTCard } from '../../../../../_metronic/helpers'
import { FC } from 'react'

const SubscriptionsPaymentsList = () => {
  return (
    <>
      <KTCard>
        <SubscriptionsPaymentsListHeader />
        <SubscriptionsPaymentsTable />
      </KTCard>
    </>
  )
}

type Props = {
  userId: string
}

const SubscriptionsPaymentsListListWrapper: FC<Props> = ({userId}) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider userId={userId}>
        <ListViewProvider>
          <SubscriptionsPaymentsList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export { SubscriptionsPaymentsListListWrapper }
