/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Subscription} from '../../core/_models'

type Props = {
  order_id: Subscription
}

const SubscriptionsPaymentsInfoCell: FC<Props> = ({order_id}) => {
  console.log('order_id', order_id)
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <span>{order_id}</span>
    </div>
  </div>
)}

export {SubscriptionsPaymentsInfoCell}
