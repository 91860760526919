/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import { getSalesReport, SalesReportResponse } from '../core/_requests'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const SalesReport: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [customerPlanReportInfo, setCustomerPlanReportInfo] = useState<{salesListReportInfo: SalesReportResponse[], totalSales: number}>()

  const getCustomerPlanReport = async () => {
    const planReport = await getSalesReport()/* {userPlanReportInfo: [{
        "month": "April",
        "count": 20
    },
    {
        "month": "May",
        "count": 30
    },
    {
        "month": "June",
        "count": 10
    },
    {
        "month": "July",
        "count": 25
    },
    {
        "month": "August",
        "count": 2
    },
    {
        "month": "September",
        "count": 2
    }], customerSubscriptionCount: 10 } */
    
    console.log('planReport', planReport)
    setCustomerPlanReportInfo(planReport)
  }

  useEffect(() => {
    getCustomerPlanReport()
  }, [])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const totalList = []
    const monthList = []
    console.log('customerPlanReportInfo :: ', customerPlanReportInfo)
    if (customerPlanReportInfo) {
        totalList.push(...customerPlanReportInfo.salesListReportInfo.map(salesPlanReport => salesPlanReport.total))
        monthList.push(...customerPlanReportInfo.salesListReportInfo.map(salesPlanReport => salesPlanReport.month))
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, totalList, monthList))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, customerPlanReportInfo])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>Sales</span>

            {/* <span className='text-gray-400 fw-bold'>Oct 8 - Oct 26 2021</span> */}
          </div>

          <div className={`fw-bolder fs-3 text-${chartColor}`}>${customerPlanReportInfo?.totalSales}</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, totalList: number[], monthList: string[]): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      // {
      //   name: 'Net Profit',
      //   data: [50, 60, 70, 80, 60, 50, 70, 60],
      // },
      {
        name: 'Income',
        data: totalList// [50, 60, 70, 80, 60, 50, 70, 60],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: monthList,// ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {SalesReport}
