import { UserSurveyListSearchComponent } from './UserSurveyListSearchComponent'

const UserSurveyListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <UserSurveyListSearchComponent />
    </div>
  )
}

export { UserSurveyListHeader }
