import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
// import { Overview } from '../modules/customer/view/component/Overview'
import { Overview } from '../modules/customer/view/component/Overview'
import { PaymentOverview } from '../modules/package-payments/payment_view/component/Overview'
import { UserSurveyViewListWrapper } from '../modules/survey/user_survey/UserSurveyViewList'
import { SubscriptionsPaymentsListListWrapper } from '../modules/customer/view/subscription-payment/SubscriptionPaymentList'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  //const Package_Payment = lazy(() => import('../modules/package_payments/Package_PaymentsManagementPage'))

  const MasterManagementPage = lazy(() => import('../modules/master_management/MasterManagementPage'))

  const SuccessStoriesPage = lazy(() => import('../modules/success_stories/story_management/successStoriesManagementPage'))
  const FormSupportPage = lazy(() => import('../modules/form_support_management/FormSupportManagementPage'))
  const SurveyPage = lazy(() => import('../modules/survey/surveyManagementPage'))
  const GeneralSettingsPage = lazy(() => import('../modules/general_settings/GeneralSettingManagementPage'))
  const PushNotificationPage = lazy(() => import('../modules/push_notification/pushnotifyManagementPage'))
  const PackagePage = lazy(() => import('../modules/package-payments/packagePaymentsManagementPage'))

  const StaffManagementPage = lazy(() => import('../modules/staff management/StaffManagementPage'))

  const CustomerPage = lazy(() => import('../modules/customer/CustomerPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='general-settings/*'
          element={
            <SuspensedView>
              <GeneralSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='package-payments/payment/overview/:paymentId'
          element={
            <>
              <SuspensedView>
               <PaymentOverview />
            </SuspensedView>
            </>
          }
        />

        <Route
          path='package-payments/*'
          element={
            <SuspensedView>
              <PackagePage />
            </SuspensedView>
          }
        />
        <Route
          path='push-notification/*'
          element={
            <SuspensedView>
              <PushNotificationPage />
            </SuspensedView>
          }
        />
        <Route
          path='master-management/*'
          element={
            <SuspensedView>
              <MasterManagementPage />
            </SuspensedView>
          }
        />

         <Route
          path='staff-management/*'
          element={
            <SuspensedView>
              <StaffManagementPage />
              </SuspensedView>
          }
        />

        
        <Route
          path='customers/all/overview/:userId'
          element={
            <SuspensedView>
              <Overview />
            </SuspensedView>
          }
        />

        <Route
          path='customers/subscribed/overview/:userId'
          element={
            <SuspensedView>
              <Overview />
            </SuspensedView>
          }
        />

        {/* <Route
          path='staff-management/*'
          element={
            <SuspensedView>
              <StaffManagementPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='customers/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />


        {/* <Route
          path='customers-view/*'
          element={
            <SuspensedView>
              <ProfilePage1 />
            </SuspensedView>
          }
        /> */}


        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='success-stories/*'
          element={
            <SuspensedView>
              <SuccessStoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='support-form/*'
          element={
            <SuspensedView>
              <FormSupportPage />
            </SuspensedView>
          }
        />
        <Route

          path='survey/user-survey/overview/:surveyId'
          element={
            <SuspensedView>
              <UserSurveyViewListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='survey/*'
          element={
            <SuspensedView>
              <SurveyPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
