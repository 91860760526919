/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Subscription} from '../../core/_models'

type Props = {
  plan: Subscription
}

const SubscriptionsPaymentsInfoPlanCell: FC<Props> = ({plan}) => {
  console.log('plan', plan)
  return (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{plan.plan?.plan_package.name}</span>
      {
        plan.plan?.plan_month ? <span>{plan.plan?.plan_month} {plan.plan?.plan_month > 1 ? <>months</>: <>month</>}</span> : (plan.plan?.plan_day ? <span>{plan.plan?.plan_day} {plan.plan?.plan_day > 1 ? <>days</>: <>day</>}</span> : plan.plan?.plan_week && <span>{plan.plan?.plan_week} {plan.plan?.plan_week > 1 ? <>weeks</>: <>week</>}</span>)
      }
      {/* <span>{plan.plan?.plan_month} month</span> */}
    </div>
  </div>
)}

export {SubscriptionsPaymentsInfoPlanCell}
