/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
// import SimpleGallery from './SimpleGallery';

import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';

import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { Payments } from '../core/_models'
import { getPaymentId } from '../core/_requests'
import { EXPORT_INVOICE_URL } from '../../../customer/view/subscription-payment/core/_requests';
// import { SubscriptionsPaymentsListListWrapper } from '../subscription-payment/SubscriptionPaymentList'
const PaymentOverview: React.FC = () => {
  let { paymentId } = useParams<{ paymentId: string }>()
  console.log('paymentId', paymentId)

  const [tab, setTab] = useState('Payment Information')

  const [Payments, setPayments] = useState<Payments>()
  const getPaymentsInfo = async () => {
    const paymentsInfo = await getPaymentId(paymentId!)
    console.log('paymentsInfo', paymentsInfo)
    setPayments(paymentsInfo)
  }

  useEffect(() => {
    getPaymentsInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const [tab, setTab] = useState('Personal Information')

  // console.log('user?.is_active', user?.is_active)

  // const date_of_birth = user?.dob && new Date(user?.dob)

  // const actionsCount = user?.user_actions_performed && user?.user_actions_performed?.length > 0 && user.user_actions_performed.reduce((acc, curr) => Object.assign(acc, {
  //   [curr.action]: 1 + (acc[curr.action] || 0)
  // }), Object.create(null))
  // console.log('actionsCount', actionsCount )

  // const userPhotos = user?.photo ? user?.photo.map((photo) => toAbsoluteUrl(photo.image_url!)) : []

  // const imageItems = userPhotos?.length && userPhotos.map(photo => {
  //   return {
  //       original: photo,
  //       thumbnail: photo
  //   }
  // })
  const paymentBreadCrumbs: Array<PageLink> = [
    {
      title: 'Payment',
      path: '/package-payments/payment',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const location = useLocation()

  // To set the breadcrumbs based on URL (for all/subscribed customers)
  const pathInfo = location.pathname.split('/')[2]

  return (
    <>
    <PageTitle breadcrumbs={paymentBreadCrumbs}>{Payments?.id}</PageTitle>
      <div className='card card-custom tab_sections_new'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Personal Information'})}
                onClick={() => setTab('Personal Information')}
                role='tab'
              >
                Payment Information
              </a>
            </li>
            
            {/* <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Content'})}
                onClick={() => setTab('Content')}
                role='tab'
              >
                Content
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Footer'})}
                onClick={() => setTab('Footer')}
                role='tab'
              >
                Footer
              </a>
            </li> */}
          </ul>
        </div>
        {/* end::Header */}

        {/* begin::Form */}
        <form className='form'>
          {/* begin::Body */}
          <div className='card-body'>
            <div className='tab-content pt-3'>
              {/* <div className={clsx('tab-pane', {active: tab === 'Payment Information'})}> */}
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.user?.user_name}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Mobile Number</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fs-6 me-2'>{Payments?.user?.mobile_number}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Email
                      </label>

                      <div className='col-lg-8 d-flex align-items-center'>
                        <span className='fs-6 me-2'>{Payments?.user?.email}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Package Name
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.plan?.plan_package?.name}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Amount
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.amount} {Payments?.currency}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Order Id
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.order_id}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Payment Id
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.payment_id}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Transaction At
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.transaction_date && new Date(Payments?.transaction_date).toLocaleTimeString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Expire At
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{Payments?.expire_date && new Date(Payments?.expire_date).toLocaleTimeString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</span>
                      </div>
                    </div>



                    {/* <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        About Yourself
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{user?.about_yourself}</span>
                      </div>
                    </div> */}

                    {/* <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        DOB
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{date_of_birth?.toLocaleString('en-US', { day: '2-digit' })}-{date_of_birth?.toLocaleString('en-US', { month: 'short' })}-{date_of_birth?.toLocaleString('en-US', { year: 'numeric' })}</span>
                      </div>
                    </div> */}

                    
                  </div>
                </div>

              <button type='button' className='btn btn-light-primary me-3' onClick={() => window.open(EXPORT_INVOICE_URL + '/' + paymentId)}>
                <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-2' />
                Download Invoice
              </button>
              {/* </div> */}

            </div>
          </div>
          {/* end::Body */}

        </form>
        {/* end::Form */}
      </div>
    </>
  )
}

export { PaymentOverview }
