/* eslint-disable jsx-a11y/anchor-is-valid */
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { UserSurveyListHeader } from './components/header/UserSurveyListHeader'
import { SurveyTable } from './table/SurveyTable'
import { KTCard, KTSVG, toAbsoluteUrl, useDebounce } from '../../../../_metronic/helpers'
import { Navigate, useParams } from 'react-router-dom'
import { ChatInner, Dropdown1 } from '../../../../_metronic/partials'
import { useEffect, useState } from 'react'
import { surveyAttendUserInfo, UserSurvey, userSurveyDetail } from './core/_models'
import { getSurveyAttendDetail } from './core/_requests'
import clsx from 'clsx'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
// import { SurveyManagementEditModal } from './survey_management-edit-modal/SurveyManagementEditModal'

const UserSurveyList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <UserSurveyListHeader />
        <SurveyTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <Navigate to={'/survey/user-survey/overview/' + itemIdForUpdate} />}
    </>
  )
}

const UserSurveyViewListWrapper = () => {

  let { surveyId } = useParams<{ surveyId: string }>()
  console.log('surveyId', surveyId)

  const [surveyAttendedUser, setSurveyAttendedUser] = useState<surveyAttendUserInfo>()
  const [surveyAttendedUserList, setSurveyAttendedUserList] = useState<surveyAttendUserInfo[]>()
  const [allUserSurveyDetail, setAllUserSurveyDetail] = useState<userSurveyDetail[]>()
  const [surveyInfo, setSurveyInfo] = useState<UserSurvey>()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)


  const singleSurveyDetail = async (searchTerm={ search: '' }) => {
    const userSurveyInfo = await getSurveyAttendDetail(surveyId!, searchTerm)
    console.log('userSurveyInfo', userSurveyInfo)
    setSurveyAttendedUser(userSurveyInfo?.surveyAttendUserInfo[0])
    setSurveyAttendedUserList(userSurveyInfo?.surveyAttendUserInfo)
    setAllUserSurveyDetail(userSurveyInfo?.userSurveyDetail)
    setSurveyInfo(userSurveyInfo?.surveyInfo)
  }

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      singleSurveyDetail({search: debouncedSearchTerm});
    }
    else singleSurveyDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  // const [user, setUser] = useState<User>()
  // const getUserInfo = async () => {
  //   const userInfo = await getUserById(userId!)
  //   console.log('userInfo', userInfo)
  //   setUser(userInfo)
  // }

  // useEffect(() => {
  //   getUserInfo();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const [tab, setTab] = useState('Personal Information')

  // console.log('user?.is_active', user?.is_active)
  
  // const date_of_birth = user?.dob && new Date(user?.dob)

  // const actionsCount = user?.user_actions_performed && user?.user_actions_performed?.length > 0 && user.user_actions_performed.reduce((acc, curr) => Object.assign(acc, {
  //   [curr.action]: 1 + (acc[curr.action] || 0)
  // }), Object.create(null))
  // console.log('actionsCount', actionsCount )

  const surveyBreadCrumbs: Array<PageLink> = [
    {
      title: 'Survey list',
      path: '/survey/user-survey',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  return (
    <>
    <PageTitle breadcrumbs={surveyBreadCrumbs}>{surveyInfo?.title}</PageTitle>
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <form className='w-100 position-relative' autoComplete='off'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder='Search by username or email...'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
          </div>

          <div className='card-body pt-5' id='kt_chat_contacts_body'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              {
                surveyAttendedUserList && surveyAttendedUserList.map((surveyAttendUserInfo, index) => {
                  return (
                    <>
                        { index>0 && <div className='separator separator-dashed d-none'></div>}
                        <div className='d-flex flex-stack py-4'>
                          <div className='d-flex align-items-center'>
                            <div className='ms-5'>
                              <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' onClick={(e) => {
                                e.preventDefault()
                                setSurveyAttendedUser(surveyAttendUserInfo)
                              }}>
                                {surveyAttendUserInfo['user.user_name']}
                              </a>
                              <div className='fw-bold text-gray-400'>{surveyAttendUserInfo['user.email']}</div>
                              <div className='fw-bold text-gray-400'>{surveyAttendUserInfo['user.mobile_number']}</div>
                            </div>
                          </div>
                        </div>
                    </>
                  )
                })
              }

            </div>
          </div>
        </div>
      </div>

      { surveyAttendedUser && <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                { surveyAttendedUser['user.user_name'] }
              </div>

              ({allUserSurveyDetail && allUserSurveyDetail.length > 0 && <div className='d-flex justify-content-center flex-column me-3'>
                  {allUserSurveyDetail[0].createdAt && <div className='d-flex flex-column'>
                    {/* <span>{new Date(allUserSurveyDetail[0].createdAt).toLocaleDateString('en-US', { day: '2-digit' })} {new Date(allUserSurveyDetail[0].createdAt).toLocaleDateString('en-US', { month: 'long' })} {new Date(allUserSurveyDetail[0].createdAt).getFullYear()}, {new Date(allUserSurveyDetail[0].createdAt).toLocaleTimeString('en-US', { timeZone: 'IST', timeZoneName: 'short', hour: '2-digit', minute: '2-digit' })}</span> */
                    <span>{new Date(allUserSurveyDetail[0].createdAt).toLocaleTimeString('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</span>
                    }
                  </div>}
              </div>})

            </div>

          </div>
          <div
            className='card-body'
            id={'kt_chat_messenger_body'}
          >
            <div
              className='scroll-y me-n5 pe-5 h-300px h-lg-auto'
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies={
                '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              }
              data-kt-scroll-wrappers={
                '#kt_content, #kt_chat_messenger_body'
              }
              data-kt-scroll-offset={'-2px'}
            >
              {
                allUserSurveyDetail && allUserSurveyDetail.map((userSurveyDetail, index) => {
                  if (userSurveyDetail.user_id === surveyAttendedUser['user.id']) {
                    return (
                      <>
                        <div
                          className={clsx(
                            'p-5 rounded',
                            `bg-light-in`,
                            'text-dark fw-bold mw-lg-400px',
                            `text-in`
                          )}
                          data-kt-element='message-text'
                          //dangerouslySetInnerHTML={{ __html: `Q:  ${userSurveyDetail.question?.question}` }}
                        >
                          Q : {userSurveyDetail.question?.question}
                        </div>

                        <div
                          className={clsx(
                            'p-5 rounded',
                            `bg-light-in`,
                            'text-dark fw-bold mw-lg-400px',
                            `text-in`,
                            `card-header`
                          )}
                          data-kt-element='message-text'
                          //dangerouslySetInnerHTML={{ __html: `A:  ${userSurveyDetail.answer?.options}` }}
                        >
                          A : {userSurveyDetail.answer?.options}
                        </div>
                      </>)
                  }
                })
              }
              {/* <div
                className={clsx(
                  'p-5 rounded',
                  `bg-light-in`,
                  'text-dark fw-bold mw-lg-400px',
                  `text-in`
                )}
                data-kt-element='message-text'
                dangerouslySetInnerHTML={{ __html: '1. What is your favorite superhero?' }}
              ></div>

              <div
                className={clsx(
                  'p-5 rounded',
                  `bg-light-in`,
                  'text-dark fw-bold mw-lg-400px',
                  `text-in`
                )}
                data-kt-element='message-text'
                dangerouslySetInnerHTML={{ __html: 'Iron man' }}
              ></div> */}


            </div>

          </div>
        </div>
      </div>}
    </div>
    </>
  )
}

export { UserSurveyViewListWrapper }
