/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
import { Subscription } from '../../core/_models'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {
  plan: Subscription
}

const SubscriptionsPaymentsAmountDateCell: FC<Props> = ({plan}) => {
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
        <span>{plan.amount} {plan.currency}</span>
    </div>
  </div>
)}

export {SubscriptionsPaymentsAmountDateCell}
