import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Country, Gender, User} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const User_URL = `${API_URL}/user`
const User_VERIFY_URL = `${API_URL}/user/verify`
const GET_COUNTRY_URL = `${API_URL}/country`
const GENDER_URL = `${API_URL}/gender`

const getCountry = (): Promise<Country[] | undefined> => {
  return axios
    .get(`${GET_COUNTRY_URL}`)
    .then((response: AxiosResponse<Response<Country[]>>) => response.data)
    .then((response: Response<Country[]>) => response.result)
}

const getGender = (): Promise<Gender[] | undefined> => {
  return axios
    .get(`${GENDER_URL}`)
    .then((response: AxiosResponse<Response<Gender[]>>) => response.data)
    .then((response: Response<Gender[]>) => response.result)
}

const getUserById = (id: string): Promise<User | undefined> => {
  return axios
    .get(`${User_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.result)
}

const verifyUserById = (id: ID, is_verified: number): Promise<User | undefined> => {
  console.log('formik.values.is_verified === ', is_verified) 
  return axios
    .put(`${User_VERIFY_URL}/${id}`, { is_verified })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.result)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${User_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${User_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {deleteUser, deleteSelectedUsers, getUserById, getCountry, getGender, verifyUserById}
