/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
// import SimpleGallery from './SimpleGallery';

import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';

import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { User } from '../core/_models'
import { getUserById, verifyUserById } from '../core/_requests'
import { SubscriptionsPaymentsListListWrapper } from '../subscription-payment/SubscriptionPaymentList'

const Overview: React.FC = () => {
  let { userId } = useParams<{ userId: string }>()
  console.log('userId', userId)

  const [user, setUser] = useState<User>()

  const [verifyUser, setVerifyUser] = useState<number>()

  const getUserInfo = async () => {
    const userInfo = await getUserById(userId!)
    console.log('userInfo', userInfo)
    setUser(userInfo)
    setVerifyUser(userInfo?.is_verified || 0)
  }

  useEffect(() => {
    getUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [tab, setTab] = useState('Personal Information')

  console.log('user?.is_active', user?.is_active)

  const date_of_birth = user?.dob && new Date(user?.dob)

  const actionsCount = user?.user_actions_performed && user?.user_actions_performed?.length > 0 && user.user_actions_performed.reduce((acc, curr) => Object.assign(acc, {
    [curr.action]: 1 + (acc[curr.action] || 0)
  }), Object.create(null))
  console.log('actionsCount', actionsCount)

  const userPhotos = user?.photo ? user?.photo.map((photo) => toAbsoluteUrl(photo.image_url!)) : []

  const imageItems = userPhotos?.length && userPhotos.map(photo => {
    return {
      original: photo,
      thumbnail: photo
    }
  })

  const subscribedCustomerBreadCrumbs: Array<PageLink> = [
    {
      title: 'Subscribed Customer list',
      path: '/customers/subscribed',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const allCustomerBreadCrumbs: Array<PageLink> = [
    {
      title: 'All Customer list',
      path: '/customers/all',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const verifyUserSchema = Yup.object().shape({
    is_verified: Yup.number()
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_verified: verifyUser
    },
    validationSchema: verifyUserSchema,
    onSubmit: async ({ is_verified }, {setSubmitting}) => {
      console.log('is_verified = ', is_verified)
      setSubmitting(true)
    }
  })

  const location = useLocation()

  // To set the breadcrumbs based on URL (for all/subscribed customers)
  const pathInfo = location.pathname.split('/')[2]

  console.log('formik.values == =: ', formik.values)

  return (
    <>
      <PageTitle breadcrumbs={pathInfo === 'subscribed' ? subscribedCustomerBreadCrumbs : allCustomerBreadCrumbs}>{user?.user_name}</PageTitle>
      <div className='card card-custom tab_sections_new'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'Personal Information' })}
                onClick={() => setTab('Personal Information')}
                role='tab'
              >
                Personal Information
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'Interest' })}
                onClick={() => setTab('Interest')}
                role='tab'
              >
                Interest
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'Photo & Video' })}
                onClick={() => setTab('Photo & Video')}
                role='tab'
              >
                Photo & Video
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'Subscription & payments' })}
                onClick={() => setTab('Subscription & payments')}
                role='tab'
              >
                Subscription & payments
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'Activity logs' })}
                onClick={() => setTab('Activity logs')}
                role='tab'
              >
                Activity logs
              </a>
            </li>

            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, { active: tab === 'Proof & verify user' })}
                onClick={() => setTab('Proof & verify user')}
                role='tab'
              >
                Proof & verify user
              </a>
            </li>

            {/* <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Content'})}
                onClick={() => setTab('Content')}
                role='tab'
              >
                Content
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Footer'})}
                onClick={() => setTab('Footer')}
                role='tab'
              >
                Footer
              </a>
            </li> */}
          </ul>
        </div>
        {/* end::Header */}

        {/* begin::Form */}
        <form className='form'>
          {/* begin::Body */}
          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', { active: tab === 'Personal Information' })}>
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.user_name}</span> */}
                        {user?.user_name? (<span className='fs-6 text-dark me-2'>{user?.user_name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Mobile Number</label>

                      <div className='col-lg-8 fv-row'>
                        <span className='fs-6 me-2'>{user?.mobile_number}</span>
                        {(user?.mobile_number && user?.is_active === 1) && <span className='badge badge-success'>Verified</span>}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Email
                      </label>

                      <div className='col-lg-8 d-flex align-items-center'>
                        <span className='fs-6 me-2'>{user?.email}</span>
                        {user?.email_verification_status === 1 && <span className='badge badge-success'>Verified</span>}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Gender</label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 me-2'>{user?.gender.gender_name}</span> */}
                        {user?.gender?.gender_name?(<span className='fs-6 text-dark me-2'>{user?.gender.gender_name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Religion</label>

                      <div className='col-lg-8'>
                      {user?.religion?.religion_name?(<span className='fs-6 text-dark me-2'>{user?.religion.religion_name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>Plan Name</label>

                      <div className='col-lg-8'>
                      {user?.user_plan_list?.[0]?.plan?.plan_package?.name?(<span className='fs-6 text-dark me-2'>{user.user_plan_list[0].plan.plan_package.name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Country
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.country?.country_name}</span> */}
                        {user?.country?.country_name ? (<span className='fs-6 text-dark me-2'>{user?.country?.country_name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Height
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.height}</span> */}
                        {user?.height ? (<span className='fs-6 text-dark me-2'>{user?.height}</span>) : '-'}

                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        About Yourself
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.about_yourself}</span> */}
                        {user?.about_yourself ? (<span className='fs-6 text-dark me-2'>{user?.about_yourself}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        DOB
                      </label>

                      <div className='col-lg-8'>
                        <span className='fs-6 text-dark'>{date_of_birth?.toLocaleString('en-US', { day: '2-digit' })}-{date_of_birth?.toLocaleString('en-US', { month: 'short' })}-{date_of_birth?.toLocaleString('en-US', { year: 'numeric' })}</span>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Interested In
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.interested_in?.interest_text}</span> */}
                        {user?.interested_in?.interest_text ? (<span className='fs-6 text-dark me-2'>{user?.interested_in?.interest_text}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Zodiac Sign
                      </label>

                      <div className='col-lg-8'>

                        {user?.zodiac_sign?.name && user?.zodiac_sign?.image ? <><img src={toAbsoluteUrl(`${user?.zodiac_sign?.image}`)} alt={user?.zodiac_sign?.name} className='me-2' /><span className='fs-6 text-dark me-2'>{user?.zodiac_sign?.name}</span></> : '-'}
                        
                        {/* <span className='fs-6 text-dark'>{user?.zodiac_sign?.name}</span> */}
                        
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Drink
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.drink?.name}</span> */}
                        {user?.drink?.name ? (<span className='fs-6 text-dark me-2'>{user?.drink?.name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Education
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.education?.education_name}</span> */}
                        {user?.education?.education_name ? (<span className='fs-6 text-dark me-2'>{user?.education?.education_name}</span>) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Looking for
                      </label>

                      <div className='col-lg-8'>
                        {/* <span className='fs-6 text-dark'>{user?.lookings_for?.text}</span> */}
                        {user?.lookings_for?.text ? (<span className='fs-6 text-dark me-2'>{user?.lookings_for?.text}</span>) : '-'}
                      </div>
                    </div>

                    {/* <div className='row mb-7'> */}
                      {/* <label className='col-lg-4 fw-bold text-muted'>
                        Sexual Orientation
                      </label> */}

                      {/* <div className='col-lg-8'> */}
                      
                        {/* <span className='fs-6 text-dark'>{user?.sexual_orientation?.text}</span> */}
                        {/* {user?.sexual_orientation?.text ? (<span className='fs-6 text-dark me-2'>{user?.sexual_orientation?.text}</span>) : '-'} */}
                        
                      {/* </div>
                    </div> */}

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Language speak
                      </label>

                      <div className='col-lg-8'>
                        {(user?.user_language_list && user?.user_language_list?.length > 0) ? user?.user_language_list?.map((language, index) => {
                          console.log('language_name', language.language.language_name)
                          return <span key={index} className='fs-6 text-dark me-2'>{language.language.language_name}</span>
                        }
                        ) : '-'
                        }
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Last login
                      </label>

                      <div className='col-lg-8'>
                        {user?.user_login_activity_info?.loginAt ? new Date(user?.user_login_activity_info?.loginAt).toLocaleTimeString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : '-'}
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        Location
                      </label>

                      <div className='col-lg-8'>
                        {user?.user_location_info?.city ? (<span className='fs-6 text-dark me-2'>{user?.user_location_info?.city}</span>) : ''}
                        {user?.user_location_info?.state ? (<span className='fs-6 text-dark me-2'>{user?.user_location_info?.state}</span>) : ''}
                        {user?.user_location_info?.country ? (<span className='fs-6 text-dark me-2'>{user?.user_location_info?.country}</span>) : '-'}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className={clsx('tab-pane', { active: tab === 'Interest' })}>
                {
                  user?.user_interest && user?.user_interest.length && user?.user_interest.map((interest, index) => {
                    return (
                      <div>
                        <div className='card-header cursor-pointer' key={index}>
                          <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>{interest.category_name}</h3>
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {
                            interest.sub_categories.map((sub_category, index) => {
                              return (
                                <div className='me-4'>
                                  <div className='middle_ciontent_nrw'>
                                    <div className='icons_list'><img src={toAbsoluteUrl(`${sub_category.sub_category_image}`)} alt={sub_category.sub_category_name} /></div>
                                    <div className='icons_list_label'>
                                      <span>{sub_category.sub_category_name}</span>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <br />
                      </div>
                    )
                  })
                }
              </div>

              <div className={clsx('tab-pane', { active: tab === 'Photo & Video' })}>

                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Photo</h3>
                  </div>
                </div>
                {/* {
                  user?.photo.map((photo, index) => photo.image_url && <div key={index} className="me-2"><img src={toAbsoluteUrl(photo.image_url)} alt='Metornic' style={{maxHeight: '500px', height: '300px'}} /></div>)
                } */}


                {/* {
                  user?.photo.map((photo, index) => photo.image_url &&
                    <SimpleGallery
                      galleryID="my-gallery"
                      images={[
                        {
                          largeURL:
                            toAbsoluteUrl(photo.image_url),
                          thumbnailURL:
                            toAbsoluteUrl(photo.image_url),
                          width: 1875,
                          height: 2500,
                        }]} />
                  )
                } */}


                {
                  // user?.photo.map((photo, index) => photo.image_url && <ImageGallery items={
                  //   [{
                  //     original: toAbsoluteUrl(photo.image_url),
                  //     thumbnail: toAbsoluteUrl(photo.image_url),
                  //   }]
                  // }/>)

                  imageItems ? <ImageGallery items={imageItems} showPlayButton={false} /> : <></>
                }


                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Video</h3>
                  </div>
                </div>
                {/* {
                  user?.video && user?.video.map((video, index) => video.video_url && <div key={index} className="me-2"><video width="750" height="500" controls><source src={toAbsoluteUrl(video.video_url)} type="video/avi" /></video></div>)
                } */}

                {/* {
                  user?.video && user?.video.map((video, index) => video.video_url && <div key={index} className="me-2"><ReactPlayer url={video.video_url} width="100%" height="100%" controls={true}></ReactPlayer></div>)
                } */}

                {
                  user?.video && user?.video.map((video, index) => video.video_url && <div key={index} className="me-2"><a target={'_blank'} rel="noopener noreferrer" href={video.video_url}>click to play/download video</a></div>)
                }

                {/* {
                  user?.video && user?.video.map((video, index) => video.video_url && 
                    <object data={video.video_url} /> 
                  )
                } */}


              </div>

              <div className={clsx('tab-pane', { active: tab === 'Subscription & payments' })}>
                <SubscriptionsPaymentsListListWrapper userId={userId!} />
              </div>

              <div className={clsx('tab-pane', { active: tab === 'Activity logs' })}>
                <div className={`card mb-5 mb-xxl-8`}>
                  {/* begin::Header */}
                  {actionsCount && <div className='card-header align-items-center border-0 mt-4'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='fw-bolder mb-2 text-dark'>Activities</span>
                      <span className='text-muted fw-bold fs-7'>{actionsCount && actionsCount[1]} Likes, {actionsCount && actionsCount[2]} Super Likes</span>
                    </h3>
                    <div className='card-toolbar'>
                    </div>
                  </div>}
                  {/* end::Header */}
                  {/* begin::Body */}
                  <div className='card-body pt-5'>
                    {/* begin::Timeline */}
                    <div className='timeline-label'>
                      {
                        user?.user_actions_performed && user?.user_actions_performed?.length > 0 && user?.user_actions_performed.map((action_performed, index) => {
                          const created_at = new Date(action_performed.createdAt)
                          if (action_performed.action === 1) {
                            return (
                              < div key={index} className='timeline-item' >
                                {/* begin::Label */}
                                < div className='timeline-label text-gray-800 fs-6' > {created_at.toLocaleTimeString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</div>
                                {/* end::Label */}
                                {/* begin::Badge */}
                                <div className='timeline-badge'>
                                  <i className='fa fa-genderless text-warning fs-1'></i>
                                </div>
                                {/* end::Badge */}
                                {/* begin::Text */}
                                <div className='timeline-content d-flex'>
                                  <span className='fw-bolder text-gray-800 ps-3'>Liked {action_performed.action_received_user.user_name}</span>
                                </div>
                                {/* end::Text */}
                              </div>
                            )
                          }
                          if (action_performed.action === 2) {
                            return (
                              <div className='timeline-item'>
                                {/* begin::Label */}
                                <div className='timeline-label text-gray-800 fs-6'>{created_at.toLocaleTimeString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</div>
                                {/* end::Label */}
                                {/* begin::Badge */}
                                <div className='timeline-badge'>
                                  <i className='fa fa-genderless text-success fs-1'></i>
                                </div>
                                {/* end::Badge */}
                                {/* begin::Content */}
                                <div className='timeline-content d-flex'>
                                  <span className='fw-bolder text-gray-800 ps-3'>Super Liked {action_performed.action_received_user.user_name}</span>
                                </div>
                                {/* end::Content */}
                              </div>
                            )
                          }
                          return <></>
                        })
                      }

                    </div>
                    {/* end::Timeline */}
                  </div>
                  {/* end: Card Body */}
                </div>
              </div>

              <div className={clsx('tab-pane', { active: tab === 'Proof & verify user' })}>

                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Proof</h3>
                  </div>
                </div>


                {
                  user?.proof_url ? <ImageGallery items={[{ original: user?.proof_url, thumbnail: user?.proof_url }]} showPlayButton={false} /> : <>Proof is not uploaded yet</>
                }


                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Verify User</h3>
                  </div>
                </div>

                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* atart: Active Input */}
                <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2'>Verify User</label>
                    {/* end::Label */}
                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='layout-builder[layout][header][fixed][desktop]'
                        checked={formik.values.is_verified === 1 ? true : false}
                        onChange={async () => { 
                          formik.setFieldValue('is_verified', !formik.values.is_verified === true ? 1 : 0)
                          await verifyUserById(user?.id, !formik.values.is_verified === true ? 1 : 0)
                          toast.success('User verification status has been updated')
                        }}
                      />
                    </label>
                  </div>
                  {/* end: Active Input*/}
                  </form>


              </div>

            </div>
          </div>
          {/* end::Body */}

        </form>
        {/* end::Form */}
      </div>
    </>
  )
}

export { Overview }
