import axios, {AxiosResponse} from 'axios'
import {SubscriptionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const SUBSCRIPTION_LIST_URL = `${API_URL}/userPlan`
const EXPORT_INVOICE_URL = `${API_URL}/userPlans/downloadPDF`

const getSubscriptionsByUserId = (user_id: string, query: string): Promise<SubscriptionsQueryResponse> => {
  return axios
    .get(`${SUBSCRIPTION_LIST_URL}/${user_id}?${query}`)
    .then((response: AxiosResponse<SubscriptionsQueryResponse>) => response.data)
}

export {getSubscriptionsByUserId, EXPORT_INVOICE_URL}
