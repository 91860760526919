import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { surveyAttendUserInfo, UserSurvey, userSurveyDetail, UserSurveyQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_SURVEY_URL = `${API_URL}/user_survey/query`
const USER_SURVEY_URL = `${API_URL}/user_survey`

const getUserSurvey = (query: string): Promise<UserSurveyQueryResponse> => {
  return axios
    .get(`${GET_USER_SURVEY_URL}?${query}`)
    .then((d: AxiosResponse<UserSurveyQueryResponse>) => d.data)
}

const getSurveyAttendDetail = (survey_id: string, searchTerm: { search: string }): Promise<{ userSurveyDetail: userSurveyDetail[], surveyAttendUserInfo: surveyAttendUserInfo[], surveyInfo: UserSurvey } | undefined> => {
  console.log('searchTerm = == = ', searchTerm.search)
  return axios
    .get(`${USER_SURVEY_URL}/${survey_id}?search=${searchTerm.search}`)
    .then((response: AxiosResponse<Response<{ userSurveyDetail: userSurveyDetail[], surveyAttendUserInfo: surveyAttendUserInfo[], surveyInfo: UserSurvey }>>) => response.data)
    .then((response: Response<{ userSurveyDetail: userSurveyDetail[], surveyAttendUserInfo: surveyAttendUserInfo[], surveyInfo: UserSurvey }>) => response.result)
}

// const getSurveyById = (id: ID): Promise<Survey | undefined> => {
//   return axios
//     .get(`${SURVEY_URL}/${id}`)
//     .then((response: AxiosResponse<Response<Survey>>) => response.data)
//     .then((response: Response<Survey>) => response.result)
// }

// const createSurvey = (survey: Survey): Promise<Survey | undefined> => {
//   return axios
//     .post(SURVEY_URL, { title: survey.title, questions: survey.questions })
//     .then((response: AxiosResponse<Response<Survey>>) => response.data)
//     .then((response: Response<Survey>) => response.result)
// }

// const updateSurvey = (survey: Survey): Promise<Survey | undefined> => {
//   return axios
//     // .put(`${EDUCATION_URL}/${education.id}`, { education_name: education.education_name, country_id: education.country_id })
//     .put(`${SURVEY_URL}/${survey.id}`, { title: survey.title, questions: survey.questions })
//     .then((response: AxiosResponse<Response<Survey>>) => response.data)
//     .then((response: Response<Survey>) => response.result)
// }

// const deleteSurvey = (surveyId: ID): Promise<void> => {
//   return axios.delete(`${SURVEY_URL}/${surveyId}`).then(() => { })
// }

// const deleteSelectedSurvey = (surveyIds: Array<ID>): Promise<void> => {
//   const requests = surveyIds.map((id) => axios.delete(`${SURVEY_URL}/${id}`))
//   return axios.all(requests).then(() => { })
// }

export { getUserSurvey, getSurveyAttendDetail }
