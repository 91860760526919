import { FC } from 'react'

type Props = {
  createdAt: string
}

const UserSurveyCreatedAtCell: FC<Props> = ({ createdAt }) => {
  const created_at = new Date(createdAt)
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {/* <span>{created_at.toLocaleDateString('en-US', { day: '2-digit' })} {created_at.toLocaleDateString('en-US', { month: 'long' })} {created_at.getFullYear()}, {created_at.toLocaleTimeString('en-US', { timeZone: 'IST', timeZoneName: 'short', hour: '2-digit', minute: '2-digit' })}</span> */}
        <span>{created_at.toLocaleTimeString('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</span>
      </div>
    </div>
  )
}

export { UserSurveyCreatedAtCell }