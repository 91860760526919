/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {
  transaction_date: string
}

const SubscriptionsPaymentsTransactionDateCell: FC<Props> = ({transaction_date}) => {
  console.log('transaction_date', transaction_date)
  const transaction_date_format = new Date(transaction_date)
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    
    {/* <div className='d-flex flex-column'>
    {transaction_date_format.toLocaleDateString('en-US', { day: '2-digit' })} {transaction_date_format.toLocaleDateString('en-US', { month: 'long' })} {transaction_date_format.getFullYear()}, {transaction_date_format.toLocaleTimeString('en-US', {timeZone: 'IST', timeZoneName: 'short', hour: '2-digit', minute: '2-digit'})}
    </div> */}

      <div className='d-flex flex-column'>
        {transaction_date_format.toLocaleTimeString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
      </div>

  </div>
)}

export {SubscriptionsPaymentsTransactionDateCell}
