import {SubscriptionsPaymentsListSearchComponent} from './SubscriptionsPaymentsListSearchComponent'

const SubscriptionsPaymentsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SubscriptionsPaymentsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SubscriptionsPaymentsListHeader}
