import React, {useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {resetPassword} from '../core/_requests'

const initialValues = {
    new_password: '',
    re_enter_password: ''
}

const resetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(50, 'Maximum 50 characters')
        .required('New password is required'),
    re_enter_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Password must match')
        .required('Reenter password is required')
})

export function ResetPassword() {
  // const { admin_id } = useParams() as { admin_id: string }
  const [searchParams] = useSearchParams()
  const admin_id = searchParams.get('id') as string
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        
        resetPassword(admin_id, values.new_password)
          .then(({data: {message}}) => {
            setHasErrors(false)
            setLoading(false)
            navigate('/auth')
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Something went wrong')
          })
      }, 1000)
    },
  })


  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Title */}
      {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Password reset is done</div>
          </div>
        )}
        {/* end::Title */}



      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
        <input
          placeholder='New Password'
          {...formik.getFieldProps('new_password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.new_password && formik.errors.new_password},
            {
              'is-valid': formik.touched.new_password && !formik.errors.new_password,
            }
          )}
          type='password'
          name='new_password'
          autoComplete='off'
        />
        {formik.touched.new_password && formik.errors.new_password && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.new_password}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Reenter Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          placeholder='Reenter Password'
          autoComplete='off'
          {...formik.getFieldProps('re_enter_password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.re_enter_password && formik.errors.re_enter_password,
            },
            {
              'is-valid': formik.touched.re_enter_password && !formik.errors.re_enter_password,
            }
          )}
        />
        {formik.touched.re_enter_password && formik.errors.re_enter_password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.re_enter_password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

      </div>
      {/* end::Action */}
    </form>
  )

}
