/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        to='/staff-management'
        title='Staff Management'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/staff-management/roles' title='Roles' hasBullet={true} />
        <AsideMenuItem to='/staff-management/staff' title='Staff' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/general-settings'
        title='Settings'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/general-settings/cms' title='CMS' hasBullet={true} />
        {/* <AsideMenuItem to='/general-settings/cron-setup' title='Cron Setup' hasBullet={true} /> */}
        <AsideMenuItem to='/general-settings/payment-gateway' title='Payment Gateway' hasBullet={true} />
        <AsideMenuItem to='/general-settings/sms-gateway' title='SMS Gateway' hasBullet={true} />
        <AsideMenuItem to='/general-settings/smtp' title='SMTP' hasBullet={true} />
        {/* <AsideMenuItem to='/general-settings/currency' title='Currency' hasBullet={true} /> */}
        {/* <AsideMenuItem to='/general-settings/language' title='Language' hasBullet={true} /> */}
        {/* <AsideMenuItem to='/general-settings/logo' title='Logo' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/push-notification'
        title='Push Notification'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/push-notification/push-notification-management' title='Push notification Management' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/master-management'
        title='Master Management'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/master-management/state' title='State' hasBullet={true} />
        <AsideMenuItem to='/master-management/city' title='City' hasBullet={true} />
        {/* <AsideMenuItem to='/master-management/country' title='Country' hasBullet={true} /> */}
        <AsideMenuItem to='/master-management/education' title='Education' hasBullet={true} />
        <AsideMenuItem to='/master-management/religion' title='Religion' hasBullet={true} />
        <AsideMenuItem to='/master-management/language' title='Language' hasBullet={true} />
        <AsideMenuItem to='/master-management/relationship' title='Relationship' hasBullet={true} />
        <AsideMenuItem to='/master-management/aboutYourSelfTemplate' title='About YourSelf' hasBullet={true} />
        <AsideMenuItem to='/master-management/interest-category' title='Interest category' hasBullet={true} />
        <AsideMenuItem to='/master-management/drink' title='Drink' hasBullet={true} />
        {/* <AsideMenuItem to='/master-management/sexual' title='Sexual' hasBullet={true} /> */}
        <AsideMenuItem to='/master-management/marital-status' title='Marital Status' hasBullet={true} />
        {/* <AsideMenuItem to='/master-management/proof-type' title='Proof Type' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/customers'
        title='Customers'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/customers/all' title='All Customers' hasBullet={true} />
        <AsideMenuItem to='/customers/subscribed' title='Subscribed Customers' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/package-payments'
        title='Package & Payments'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/package-payments/package' title='Package' hasBullet={true} />
        <AsideMenuItem to='/package-payments/plan' title='Plan' hasBullet={true} />
        <AsideMenuItem to='/package-payments/payment' title='Payment' hasBullet={true} />
      
      
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/survey'
        title='Survey'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/survey/survey-management' title='Survey Management' hasBullet={true} />
        <AsideMenuItem to='/survey/user-survey' title='Survey Response' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        to='/success-stories'
        title='Success Stories'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/success-stories/success-stories-management' title='Story Management' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItem to='/success-stories/success-stories-management' title='Story Management' hasBullet={true} /> */}
      
      <AsideMenuItem to='/success-stories/success-stories-management' title='Story Management' icon='/media/icons/duotune/general/gen022.svg' />
      <AsideMenuItem to='/support-form/support-form-management' title='Support Form Management' hasBullet={true} />


    </>
  )
}
